import { useRef, useState } from 'react';
import { useClickOutside } from '@zola/zola-ui/src/hooks/useClickOutside';
import { CheckboxField } from '@zola/zola-ui/src/components/Form/CheckboxField';
import { ChevronDownIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronDown';
import capitalizeFirst from '@zola-helpers/client/dist/es/transformers/capitalizeFirst';
import type { Filter } from './type';

import {
  FilterDropdownContainer,
  StyledDropdownListBox,
  FilterButton,
  ListOption,
} from './FilterDropdown.styles';

interface FilterDropdownProps {
  filter: Filter;
  onChange: (key: string, val: string) => void;
}

export const FilterDropdown: React.FC<FilterDropdownProps> = ({ filter, onChange }) => {
  const { key, label, value, options, isMulti } = filter;
  const [expanded, setExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside({ ref, onClickOutside: () => setExpanded(false), isOpen: expanded });

  const handleChange = (val?: string) => {
    onChange(key, val || '');
    setExpanded(false);
  };

  return (
    <FilterDropdownContainer ref={ref}>
      <FilterButton
        onClick={() => setExpanded((currentStatus) => !currentStatus)}
        active={Boolean(value?.length) || expanded}
      >
        {capitalizeFirst(label, 1)} {value?.length && isMulti ? `(${value.length})` : undefined}{' '}
        <ChevronDownIcon />
      </FilterButton>
      {isMulti && expanded && (
        <StyledDropdownListBox onClose={() => setExpanded(false)}>
          {options?.map((option, index) => (
            <CheckboxField
              key={index}
              id={option.label || ''}
              label={option.label}
              onChange={() => handleChange(option.value)}
              checked={value?.includes(option.value || '')}
            />
          ))}
        </StyledDropdownListBox>
      )}
      {!isMulti && expanded && (
        <StyledDropdownListBox onClose={() => setExpanded(false)}>
          <ul>
            {options?.map((option, index) => (
              <ListOption
                key={index}
                id={option.label || ''}
                selected={option.value === value}
                onClick={() => handleChange(option.value)}
              >
                {option.label}
              </ListOption>
            ))}
          </ul>
        </StyledDropdownListBox>
      )}
    </FilterDropdownContainer>
  );
};
