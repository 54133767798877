import moment from 'moment';
import type { WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';
import { ShipmentContainer } from './RegistryTileShipment.styles';

interface RegistryTileShipmentProps {
  item: WRegistryCollectionItemView;
}

export const RegistryTileShipment: React.FC<RegistryTileShipmentProps> = ({ item }) => {
  /**
   * Not purchased
   * Not a group gift
   */
  const isAvailableForShipping =
    item.actions?.add_to_cart &&
    !item.contributions?.show_as_fulfilled &&
    !item.contributions?.group_gift;

  if (!isAvailableForShipping) return null;

  const isFreeShipping = item.free_shipping;
  const earlyDate = item.arrival_date_range?.min
    ? moment.utc(item.arrival_date_range?.min).format('MMM D, YYYY')
    : null;

  if (!isFreeShipping && !earlyDate) return null;

  return (
    <ShipmentContainer>
      {isFreeShipping && <div>Free shipping</div>}
      {earlyDate && <div>Available to ship {earlyDate}</div>}
    </ShipmentContainer>
  );
};
