import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { Footer, ClearAll } from './FilterSideDrawerFooter.style';

interface FilterSideDrawerFooterProps {
  total?: number;
  onClose?: () => void;
  onClear?: () => void;
  showClear?: boolean;
}
export const FilterSideDrawerFooter: React.FC<FilterSideDrawerFooterProps> = ({
  total,
  onClose,
  onClear,
  showClear,
}) => {
  return (
    <Footer>
      {showClear ? <ClearAll onClick={onClear || onClose}>Clear</ClearAll> : null}
      <ButtonV3 size="large" onClick={onClose}>
        {total ? `Show ${total} results` : 'Show results'}
      </ButtonV3>
    </Footer>
  );
};
