import { useState } from 'react';
import { ChevronDownIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronDown';
import { CheckboxField } from '@zola/zola-ui/src/components/Form/CheckboxField';
import RadioFieldV2 from '@zola/zola-ui/src/components/Form/RadioFieldV2/RadioField/RadioFieldV2';
import capitalizeFirst from '@zola-helpers/client/dist/es/transformers/capitalizeFirst';
import { getActivedFilterLabels } from './utils';
import type { Filter } from './type';

import {
  FilterContainer,
  FilterHeader,
  FilterTitle,
  FilterSelectedOptions,
  FilterLabel,
  FilterExpendIndentify,
  FilterContent,
} from './FilterSideDrawerItem.style';

interface FilterDrawerItemProps {
  filter: Filter;
  onChange?: (key: string, val?: string) => void;
}

export const FilterDrawerItem: React.FC<FilterDrawerItemProps> = ({ filter, onChange }) => {
  const { label, key, value, options, isMulti } = filter;
  const [expanded, setExpanded] = useState(false);
  const toggle = () => setExpanded((currentState) => !currentState);

  if (!options) return null;

  const selectedOptions = options.filter((option) =>
    isMulti ? value?.includes(option.value as string) : option.value === value
  );

  const selectedFtilerLabels = getActivedFilterLabels(
    selectedOptions.map((option) => option.label)
  );

  const count = selectedOptions?.length;

  return (
    <FilterContainer>
      <FilterHeader>
        <FilterTitle onClick={toggle}>
          <FilterLabel>
            {capitalizeFirst(label, 1)} {isMulti && count ? `(${count})` : null}
          </FilterLabel>
          <FilterExpendIndentify expanded={expanded}>
            <ChevronDownIcon width={24} height={24} />
          </FilterExpendIndentify>
        </FilterTitle>
        {selectedFtilerLabels ? (
          <FilterSelectedOptions>{selectedFtilerLabels} </FilterSelectedOptions>
        ) : null}
      </FilterHeader>
      {expanded ? (
        <FilterContent>
          {!isMulti &&
            options.map((option) => (
              <RadioFieldV2
                key={option.value}
                id={option.value || label}
                label={option.label}
                onChange={() => onChange && onChange(key, option.value)}
                checked={value === option.value}
              />
            ))}
          {isMulti &&
            options.map((option) => (
              <CheckboxField
                key={option.value}
                id={option.value || label}
                label={option.label}
                onChange={() => onChange && onChange(key, option.value || '')}
                checked={value?.includes(option.value || '')}
              />
            ))}
        </FilterContent>
      ) : null}
    </FilterContainer>
  );
};
