import { useRef, useState } from 'react';
import { useClickOutside } from '@zola/zola-ui/src/hooks/useClickOutside';
import { RadioField } from '@zola/zola-ui/src/components/Form/RadioFieldV2/RadioField';
import { ChevronDownIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronDown';
import { SORTS } from './constants';
import {
  FilterDropdownContainer,
  StyledDropdownListBox,
  FilterButton,
} from './FilterDropdown.styles';

interface SortDropdownProps {
  value: string;
  onChange?: (val: string) => void;
}

export const SortDropdown: React.FC<SortDropdownProps> = ({ value, onChange }) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside({ ref, onClickOutside: () => setExpanded(false), isOpen: expanded });

  const handleSortChange = (val: string) => {
    if (!onChange) return;

    onChange(val);
    setExpanded(false);
  };

  return (
    <FilterDropdownContainer ref={ref}>
      <FilterButton
        onClick={() => setExpanded((currentStatus) => !currentStatus)}
        active={expanded}
      >
        Sort: {SORTS.find((option) => option.value === value)?.label}
        <ChevronDownIcon />
      </FilterButton>
      {expanded && (
        <StyledDropdownListBox onClose={() => setExpanded(false)}>
          {SORTS.map((option, index) => (
            <RadioField
              key={index}
              id={option.label}
              label={option.label}
              onChange={() => handleSortChange(option.value)}
              checked={value === option.value}
            />
          ))}
        </StyledDropdownListBox>
      )}
    </FilterDropdownContainer>
  );
};
