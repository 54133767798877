import { useMemo } from 'react';

import type { WProductView, WRegistryCollectionItemView } from '@zola/svc-web-api-ts-client';

import { PublicRegistryTile } from './PublicRegistryTile';
import { RegistryZolaGiftCard } from './RegistryZolaGiftCard';
import { RegistryTilesContainer, PublicTileContainer } from './PublicRegistryTiles.styles';
import type { ListItem } from './types';
import { isItemAvailable } from './utils';

interface PublicRegistryTilesProps {
  items?: WRegistryCollectionItemView[];
  zolaGiftCard?: WProductView;
  onItemClick: (item: WRegistryCollectionItemView | WProductView, qty?: number) => void;
  openItemDetail: (item: WRegistryCollectionItemView) => void;
  isZolaGiftCardEnabled?: boolean;
}

export const PublicRegistryTiles: React.FC<PublicRegistryTilesProps> = ({
  items = [],
  zolaGiftCard,
  onItemClick,
  openItemDetail,
  isZolaGiftCardEnabled,
}) => {
  const list: ListItem[] = useMemo(() => {
    const availableItemCount = items?.filter(isItemAvailable).length || 0;
    const newList: ListItem[] = [...items];
    if (zolaGiftCard) {
      newList.splice(availableItemCount, 0, { type: 'zolaGiftCard' });
    }
    return newList;
  }, [items, zolaGiftCard]);

  return (
    <RegistryTilesContainer>
      {list.map((item) => {
        if (item.type === 'placeholder') return null;

        if (item.type === 'zolaGiftCard') {
          return (
            <PublicTileContainer key="zolaGiftCard">
              <RegistryZolaGiftCard
                hideMobileEdit
                onItemClick={onItemClick}
                defaultGiftCard={zolaGiftCard}
                isZolaGiftCardEnabled={isZolaGiftCardEnabled}
              />
            </PublicTileContainer>
          );
        }

        return (
          <PublicTileContainer key={item.item_id}>
            <PublicRegistryTile
              draggable={false}
              item={item}
              onItemClick={onItemClick}
              openItemDetail={openItemDetail}
            />
          </PublicTileContainer>
        );
      })}
    </RegistryTilesContainer>
  );
};
