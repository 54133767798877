import ApiService from '../util/apiService';

import {
  receivedCollectionInfoByRegistryId,
  receivedCollectionItemByCollectionId,
  receivedRegistryBySlug,
  receivedRegistryInfoByCollectionId,
  receiveRegistry,
  requestCollectionInfoByRegistryId,
  requestCollectionItemByCollectionId,
  requestRegistry,
  requestRegistryBySlug,
  requestRegistryInfoByCollectionId,
} from './types/RegistryActionTypes';

export const updateRegistryDefaultGiftCard = (enable) => (dispatch) => {
  return ApiService.put(`/web-registry-api/v1/registry/default-gift-card`, {
    gift_card_enabled: enable,
  }).then((json) => dispatch(receiveRegistry(json)));
};

const requestRegistryInfoBySlug = (slug) => (dispatch) => {
  dispatch(requestRegistryBySlug());
  return ApiService.get(`/web-registry-api/v1/registry/slug/${slug}`).then((json) =>
    dispatch(receivedRegistryBySlug(json))
  );
};

const fetchRegistryCollectionItem = (collectionItemId) => (dispatch) => {
  dispatch(requestCollectionItemByCollectionId());
  return ApiService.get(`/web-registry-api/v1/registryCollection/item/${collectionItemId}`).then(
    (json) => dispatch(receivedCollectionItemByCollectionId(json))
  );
};

const fetchRegistryInfoByCollectionItemId = (collectionItemId) => (dispatch) => {
  dispatch(requestRegistryInfoByCollectionId());
  return ApiService.get(`/api/v0/registry/collection-item-id/${collectionItemId}`).then((json) =>
    dispatch(receivedRegistryInfoByCollectionId(json))
  );
};

const fetchCollectionInfoByRegistryId = (registryId) => (dispatch) => {
  dispatch(requestCollectionInfoByRegistryId());
  return ApiService.get(`/web-registry-api/v1/registry/${registryId}/collection-previews`).then(
    (json) => dispatch(receivedCollectionInfoByRegistryId(json))
  );
};

const requestRegistryInfoById = () => (dispatch) => {
  dispatch(requestRegistry());
  return ApiService.get('/web-registry-api/v1/registry/user-info')
    .then((json) => {
      dispatch(receiveRegistry(json));
      return json;
    })
    .catch((e) => e);
};

export {
  requestRegistryInfoBySlug,
  fetchRegistryCollectionItem,
  fetchRegistryInfoByCollectionItemId,
  fetchCollectionInfoByRegistryId,
  requestRegistryInfoById,
};
