import { ProgressBarContainer, ProgressBar, Percent } from './RegistryTilePrice.styles';

interface ProgressBarProps {
  percentage?: number;
}

export const RegistryTileProgressBar: React.FC<ProgressBarProps> = ({ percentage }) => (
  <ProgressBarContainer>
    <ProgressBar percentage={percentage}>
      <span />
    </ProgressBar>
    <Percent>{percentage}%</Percent>
  </ProgressBarContainer>
);
